:root {
  --blue: #1976d2;
  --light-blue: #82a6c9;
  --transparent: #0000;
  --red: #dc004e;
  --light-red: #cf6a8d;

  --button-bg: #ffffff10;
  --selected-button-bg: #ffffff15;
}

/* Buttons */
.delete-button {
  color: var(--red) !important;
}
.delete-button-toggled {
  color: white !important;
  background-color: #dc004dc9 !important;
}

.restore-button {
  color: var(--blue) !important;
}
.restore-button-toggled {
  color: white !important;
  background-color: #1976d2d8 !important;
}

.cancel {
  margin-left: 3px;
}

/* Votes */
.votes {
  overflow: hidden;
  background-color: var(--button-bg);
  border-radius: 5px;
  display: grid;
  grid-template-columns: 26px 28px 26px;
  align-items: center;
}
.votes button {
  overflow: unset !important;
}
.votes p {
  cursor: default;
}
.vote-button {
  user-select: none;
  width: 31px;
  height: 31px;
  background-color: var(--transparent) !important;
}
.upvote-button-selected {
  color: var(--blue) !important;
}
.downvote-button-selected {
  color: var(--red) !important;
}

section {
  transform: translateY(-60px);
}

.id {
  display: inline-block;
  color: #5c5c5c;
  text-decoration: none;
  padding-right: 2px;
}
.username {
  display: inline-block;
  color: #206bb3;
}
.date {
  display: inline-block;
  padding-left: 2px;
  padding-right: 5px;
}

.grid-item {
  text-align: center;
}

.grid-item-sanottu-text {
  max-width: 100%;
  grid-column-start: 1;
  grid-column-end: 4;
  text-align: left;
}

.grid-item-left {
  text-align: left;
  justify-self: start;
}

.grid-item-right {
  text-align: right;
  justify-self: end;
}

.sanottu-text {
  word-wrap: break-word;
  padding-bottom: 5px;
  font-family: monospace;
  line-height: 20.0167px; /* Same as in material ui textfield */
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 100px;
  transform: translate(-50%, -50%);
  text-align: center;
  justify-content: center;
}

.sanottu {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto auto;
  align-items: center;
}
.sanottu-hidden {
  display: grid;
  grid-template-columns: auto auto 80px;
  align-items: center;
}
.sanottu :disabled,
.sanottu .disabled {
  opacity: 0.6;
}

.sanottu button {
  overflow: hidden;
  user-select: none;
  font-size: 14px;
  padding: 5px;
  background-color: var(--button-bg);
  color: white;
  border-radius: 5px;
  border: 2px solid var(--transparent);
  cursor: pointer;
}
.sanottu button:disabled {
  cursor: default;
}
.sanottu p {
  font-size: 15px;
  white-space: pre-wrap;
  margin: 3px;
}

hr {
  opacity: 0.4;
}
.sanottu-hr:last-child {
  opacity: 0;
}

@media (hover: hover) and (pointer: fine) {
  .vote-button:hover {
    color: var(--light-blue) !important;
  }
  .downvote-button:hover {
    color: var(--light-red) !important;
  }
  .sanottu button:hover {
    background-color: var(--selected-button-bg);
  }
  .delete-button-toggled:hover {
    background-color: var(--red) !important;
  }
  .restore-button-toggled:hover {
    background-color: var(--blue) !important;
  }
  .vote-button:disabled:hover {
    color: white !important;
  }
  .id:hover {
    text-decoration: underline;
  }
}
